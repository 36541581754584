const names = {
  TRACKER_ID: 'tracker_id',
  VEH_ID: 'veh_id',
  NAME: 'tracker_name',
  DESCRIPTION: 'tracker_description',
  INPUTS: 'inputs',
  OUTPUTS: 'outputs',
  ENABLE: 'enable',
  BATTERY: 'battery'
}

const resourceDisplayName = {
  [names.TRACKER_ID]: 'Id',
  [names.NAME]: 'Nombre',
  [names.DESCRIPTION]: 'Descripcion',
  [names.INPUTS]: 'Entradas',
  [names.OUTPUTS]: 'Salidas',
  [names.ENABLE]: 'Estado',
  [names.BATTERY]: 'Bateria'
}

const namesIO = {
  TRACKER_ID: 'id',
  NAME: 'name',
  DESCRIPTION: 'description',
  TYPE: 'type',
  VALUE: 'value',
  ENABLE: 'enable'
}

const resourceDisplayNameIO = {
  [namesIO.TRACKER_ID]: 'Id',
  [namesIO.NAME]: 'Nombre',
  [namesIO.DESCRIPTION]: 'Descripcion',
  [namesIO.VALUE]: 'Valor',
  [namesIO.TYPE]: 'Tipo',
  [namesIO.ENABLE]: 'Habilitada'
}

const fields = [
  {
    key: names.NAME,
    label: resourceDisplayName[names.NAME],
    sortable: true
  },
  {
    key: names.DESCRIPTION,
    label: resourceDisplayName[names.DESCRIPTION],
    sortable: true
  },
  {
    key: 'enable',
    label: 'Estado',
  },
  {
    key: 'in_details',
    label: 'Modo',
  },
  {
    key: 'out_details',
    label: 'Bomba',
  },
  {
    key: 'alerts',
    label: 'Actualizado',
  },
  {
    key: 'show_details',
    label: '',
    thStyle: {width: '30px !important'}
  }
]

const namesIn = {
  // IN0 = Confirmación de marcha
  // IN1 = Estado Automatico
  MARCH: 0,
  AUT0: 1
}

export default {
  names,
  namesIO,
  namesIn,
  fields,
  methods: {
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    },
    resourceDisplayNameIO (regKey) {
      return resourceDisplayNameIO[regKey]
    },
  }
}
