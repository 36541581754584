<template>
  <b-card id="content-wrapper-mf-command">

    <div class="config-title">
      <div class="config-title-text">
        <slot name="Title"></slot>
      </div>
      <template v-if="isViewForm">
        <div class="config-title-index"><h5>{{ indexSelected + 1 }} / {{ items.length}}</h5></div>
      </template>
    </div>

    <div class="config-info">
      <template v-if="!isViewForm">
        <ItemTable
          :items="items"
          :fields="fields"
          :indexSelected="indexSelected"
          :fileName="fileName"
          @change-view="onChangeView"
          @selected="onSelected"
          >
       <template v-slot:ItemTableEnableCell_="{item}">
          <slot name="ItemTableEnableCell"
                v-bind:item="item"
          ></slot>
        </template>
        <template v-slot:ItemTableModeCell_="{item}">
          <slot name="ItemTableModeCell"
                v-bind:item="item"
          ></slot>
        </template>
        <template v-slot:ItemTableOutCell_="{item}">
          <slot name="ItemTableOutCell"
                v-bind:item="item"
          ></slot>
        </template>
        <template v-slot:ItemTableAlertCell_="{item}">
          <slot name="ItemTableAlertCell"
                v-bind:item="item"
          ></slot>
        </template>
        <template v-slot:ItemTableLayout_="{item}">
          <slot name="ItemTableLayout"
                v-bind:item="item"
          ></slot>
        </template>
        </ItemTable>
      </template>

      <template v-else>
        <ItemForm
          :items="items"
          :indexSelected="indexSelected"
          @change-view="onChangeView"
          @record-prev="onPrev"
          @record-next="onNext"
          @record-update="onUpdate"
          @record-delete="onDelete"
          @record-create="onCreate"
          >
            <template v-slot:ItemFormLayout_="{itemForm, isFormDisable}">
              <slot name="ItemFormLayout"
                    v-bind:itemForm="itemForm"
                    v-bind:isFormDisable="isFormDisable"
              ></slot>
            </template>
        </ItemForm>
      </template>
    </div>
  </b-card>
</template>

<script>
import ItemForm from './ItemForm/Index'
import ItemTable from './ItemTable/Index'
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import crudService from "../../../../ingeo-mf-styleguide/src/services/crud";
/// #else
import { crudService } from "@ingeo-mf/styleguide";
/// #endif

export default {
  name: 'Crud',
	components: {
		ItemForm,
		ItemTable
  },
  props: {
    fileName: {
      required: true,
      type: String
    },
    items: {
      required: true,
      type: Array
    },
    fields: {
      required: true,
      type: Array
    },
    service: {
      required: true,
      type: Object
    },
  },
  data () {
    return {
      isViewForm: false,
      indexSelected: -1,
    }
  },
	methods: {
    onChangeView() {
      // TODO control de items lenght CERO
      this.isViewForm = !this.isViewForm
      if(!this.isViewForm) {
        this.indexSelected = -1
      } else if (this.indexSelected === -1) {
          this.indexSelected = 0
      }
    },
    onSelected(index) {
      this.indexSelected = index
    },
    onPrev() {
      // TODO control de items lenght CERO
      if(this.indexSelected > 0) {
        this.indexSelected--
      } else {
        this.indexSelected = this.items.length - 1
      }
    },
    onNext() {
      // TODO control de items lenght CERO
      if(this.indexSelected < this.items.length - 1) {
        this.indexSelected ++
      } else {
        this.indexSelected = 0
      }
    },
    onCreate(item) {
      console.log('onCreate(item) >> ', item)
      console.log('onCreate(table) >> ', this.service.TABLE)
      console.log('onUpdate(table) >> ', this.service.TABLE)
      console.log('onUpdate(primary_key) >> ', this.service, this.service.PRIMARY_KEY)
      // this.items.push(item)
      // this.indexSelected = this.items.length - 1
      // this.items[this.indexSelected] = item
     crudService.create(this.service.TABLE, item[this.service.PRIMARY_KEY], item)
      .then((res) => {
          console.log('create response >> ', res)
        })
        .catch((error) => {
           console.log('create response error >> ', error)
        });
    },
    onUpdate(item, index) {
      console.log('onUpdate(item) >> ', item)
      console.log('onUpdate(index) >> ', index)
      console.log('onUpdate(table) >> ', this.service.TABLE)
      console.log('onUpdate(primary_key) >> ', this.service.PRIMARY_KEY)
      this.items[this.indexSelected] = item
      crudService.update(this.service.TABLE, item[this.service.PRIMARY_KEY], item)
        .then((res) => {
          console.log('update response >> ', res)
        })
        .catch((error) => {
          console.log('update response error >> ', error)
        });
    },
    onDelete(item) {
      console.log("onDelete -> item", item)
      //console.log('onDelete(item) >> ', index)
      console.log('onDelete(table) >> ', this.service)
      // this.items.splice( index, 1 )
      // if(index != 0) {
      //   this.onPrev()
      // }
       crudService.delete(this.service.TABLE, item[this.service.PRIMARY_KEY])
        .then((res) => {
          console.log('delete response >> ', res)
        })
        .catch((error) => {
          console.log('delete response error >> ', error)
        });
    }
  }
}
</script>

<style lang="css">
  #content-wrapper-mf-command {
    height: calc(100vh - 70px);
    margin-top: 65px;
  }
</style>
