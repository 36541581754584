var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"content-wrapper-mf-command"}},[_c('div',{staticClass:"config-title"},[_c('div',{staticClass:"config-title-text"},[_vm._t("Title")],2),(_vm.isViewForm)?[_c('div',{staticClass:"config-title-index"},[_c('h5',[_vm._v(_vm._s(_vm.indexSelected + 1)+" / "+_vm._s(_vm.items.length))])])]:_vm._e()],2),_c('div',{staticClass:"config-info"},[(!_vm.isViewForm)?[_c('ItemTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"indexSelected":_vm.indexSelected,"fileName":_vm.fileName},on:{"change-view":_vm.onChangeView,"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"ItemTableEnableCell_",fn:function(ref){
var item = ref.item;
return [_vm._t("ItemTableEnableCell",null,{"item":item})]}},{key:"ItemTableModeCell_",fn:function(ref){
var item = ref.item;
return [_vm._t("ItemTableModeCell",null,{"item":item})]}},{key:"ItemTableOutCell_",fn:function(ref){
var item = ref.item;
return [_vm._t("ItemTableOutCell",null,{"item":item})]}},{key:"ItemTableAlertCell_",fn:function(ref){
var item = ref.item;
return [_vm._t("ItemTableAlertCell",null,{"item":item})]}},{key:"ItemTableLayout_",fn:function(ref){
var item = ref.item;
return [_vm._t("ItemTableLayout",null,{"item":item})]}}],null,true)})]:[_c('ItemForm',{attrs:{"items":_vm.items,"indexSelected":_vm.indexSelected},on:{"change-view":_vm.onChangeView,"record-prev":_vm.onPrev,"record-next":_vm.onNext,"record-update":_vm.onUpdate,"record-delete":_vm.onDelete,"record-create":_vm.onCreate},scopedSlots:_vm._u([{key:"ItemFormLayout_",fn:function(ref){
var itemForm = ref.itemForm;
var isFormDisable = ref.isFormDisable;
return [_vm._t("ItemFormLayout",null,{"itemForm":itemForm,"isFormDisable":isFormDisable})]}}],null,true)})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }