<template>
<div>
      <b-card bg-variant="light" style="padding-top: 65px" >
        <p style="display:inline"> Tanque: <strong> {{tanque.value}} </strong></p>
        <p style="display:inline"> [bar] - Actualizado: <strong> {{tanque.fecha}} </strong> </p>
      </b-card>
  <crud :items="items" :fields="fields" :fileName="fileName" :service="{}" style="margin-top: 0px" >

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:ItemFormLayout="{itemForm, isFormDisable}">

      <b-card bg-variant="light" >
        <!-- Fila 1 -->
        <b-form-row>
          <b-col sm="4">
            <b-form-group label-for="input-1" id="input-1">
              <label>{{ resourceDisplayName(names.NAME) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-1" required type="text"
                v-model="itemForm[names.NAME]"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label-for="input-2" id="input-2">
              <label>{{ resourceDisplayName(names.DESCRIPTION) }}</label>
              <b-form-input :disabled="isFormDisable" id="input-2" required type="text"
                v-model="itemForm[names.DESCRIPTION]"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <!-- Fila 2x -->
        <template v-for="elem in itemForm[names.INPUTS]">
          <b-form-row v-if="isEnable(elem)" v-bind:key="elem.id">
            <b-col sm="4">
              <b-form-group label-for="input-1" id="input-1">
                <label>{{ resourceDisplayNameIO(namesIO.NAME) }}</label>
                <b-form-input :disabled="isFormDisable" id="input-1" required type="text"
                  v-model="elem[namesIO.NAME]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group label-for="input-2" id="input-2">
                <label>{{ resourceDisplayNameIO(namesIO.DESCRIPTION) }}</label>
                <b-form-input :disabled="isFormDisable" id="input-2" required type="text"
                  v-model="elem[namesIO.DESCRIPTION]"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </template>
      </b-card>

    </template>

    <!-- Estado  -->
    <template v-slot:ItemTableEnableCell="{item}">
      <b-icon :icon="getOutEnable(item).icon" scale="1.5" :variant="getOutEnable(item).variant"></b-icon>
      <!-- <b-badge pill :variant="getOutMode(item).variant">{{ getOutMode(item).text }} </b-badge> -->
    </template>

    <!-- Modo -->
    <template v-slot:ItemTableModeCell="{item}">
      <!-- <b-badge pill :variant="getOutMode(item).variant">{{ getOutMode(item).text }} </b-badge> -->
      <b-badge v-if="getElementMetaData(getElementFromKeyName('modo', 'inputs', item)).type==='badge'"
               pill
               :variant="getElementMetaData(getElementFromKeyName('modo', 'inputs', item)).class">
          {{ getElementMetaData(getElementFromKeyName('modo', 'inputs', item)).content }}
      </b-badge>

      <b-icon v-if="getElementMetaData(getElementFromKeyName('modo', 'inputs', item)).type==='icon'"
              :icon="getElementMetaData(getElementFromKeyName('modo', 'inputs', item)).content"
              scale="1"
              :variant="getElementMetaData(getElementFromKeyName('modo', 'inputs', item)).class">
      </b-icon>

    </template>

    <!-- Bomba -->
    <template v-slot:ItemTableOutCell="{item}">
      <b-badge v-if="getElementMetaData(getElementFromKeyName('marcha', 'inputs', item)).type==='badge'" pill :variant="getElementMetaData(getElementFromKeyName('marcha', 'inputs', item)).class">{{ getElementMetaData(getElementFromKeyName('marcha', 'inputs', item)).content }} </b-badge>
      <b-icon v-if="getElementMetaData(getElementFromKeyName('marcha', 'inputs', item)).type==='icon'" :icon="getElementMetaData(getElementFromKeyName('marcha', 'inputs', item)).content" scale="1" :variant="getElementMetaData(getElementFromKeyName('marcha', 'inputs', item)).class"></b-icon>
      <!-- <b-icon :icon="getOutState(item).icon" :animation="getOutState(item).animation" :variant="getOutState(item).variant" font-scale="1.5"></b-icon> -->
      <!-- <template v-if="isAAGEnable(item)">
        <strong class="ml-2">( {{getAAGValue(item)}} % )</strong>
      </template> -->
    </template>

    <!-- Alertas -->
    <template v-slot:ItemTableAlertCell="{item}">
      <template v-if="isAlarmGuardaMotor(item)">
        <b-icon icon="exclamation-triangle-fill" animation="fade" variant="danger" font-scale="1.5" class="mr-3" v-b-tooltip="'ALR. Guarda Motor'"></b-icon>
      </template>
      <template v-if="isAlarmConfirmacionMarcha(item)">
        <b-icon icon="arrow-clockwise" animation="spin" variant="warning" font-scale="1.5" class="mr-3" v-b-tooltip="'ALR. Confirmacion de Marcha'"></b-icon>
      </template>
      <template v-if="isAlarmEnergia(item)">
        <b-icon icon="battery-charging" animation="fade" variant="info" font-scale="1.5" class="mr-3" v-b-tooltip="'ALR. Energia'"></b-icon>
      </template>
      <p>{{item['battery']}}</p>
    </template>



    <!-- VISTA DE DETALLES DE CADA FILA -->
    <template v-slot:ItemTableLayout="{item}">
      <h5>Tablero de Comandos</h5>

      <b-card-group deck>
        <template v-for="elem in item[names.OUTPUTS]">
          <b-card v-if="isEnable(elem)" v-bind:key="elem.id"
            header="Habilitación de Salida"
            align="center"
            :border-variant="getOutMode(item).variant"
            :header-bg-variant="getOutMode(item).variant"
            :header-text-variant="getOutMode(item).variantText"
          >
            <b-row class="card-command-detail">
              <!-- <b-col align="right" class="mt-2"> -->
                <!-- <div> {{ resourceDisplayNameIO(namesIO.NAME) }}</div>
                <div> {{ resourceDisplayNameIO(namesIO.DESCRIPTION) }}</div> -->
                <!-- <div class="mt-2"> {{ elem[namesIO.NAME] }}</div> -->
                <!-- <div class="mt-2"> Modo </div> -->
              <!-- </b-col> -->
              <b-col align="left" class="ml-2 mt-4 col-sm-0">
                  <!-- <div> {{ elem[namesIO.NAME] }}</div> -->
                  <div class="mt-3"> {{ elem[namesIO.DESCRIPTION] }}</div>
                  <!-- <div class="mt-2"> {{ getOutMode(item).text }} </div> -->
              </b-col>

              <b-col align="left" class="mt-2">
                <b-button
                    class="btn-output-cmd btn-circle mt-1"
                    size="sm"
                    :disabled="!isEnable(elem)"
                    :variant="getBtnOutVariant(elem)"
                    @click="setOutput(item, elem)"
                > {{getElementMetaData (elem).content}} </b-button>
                <vue-ellipse-progress
                  class="btn-output-cmd progress-circle"
                  :progress="20"
                  color="blue"
                  emptyColor="#8ec5fc"
                  :size="120"
                  :thickness="5"
                  emptyThickness="10%"
                  lineMode="in 2"
                  :legend="false"
                  dash="60 0.9"
                  animation="reverse 700 400"
                  :noData="false"
                  :loading="(elem.updating)?true:false"
                  fontColor="gray"
                  :gap="10"
                  dot="5 blue"
                > </vue-ellipse-progress>
              </b-col>

              <p v-if="getOutMode(item).text!=='AUTOMATICO'" class="ml-3 text-info">Solo válido en modo Automático</p>

            </b-row>
          </b-card>
        </template>

        <b-card
          align="center"
          header="Entradas Digitales"
          border-variant="info"
          header-bg-variant="info"
          header-text-variant="white"
        >
          <template v-for="elem in item[names.INPUTS]">
            <b-row v-if="isEnable(elem) & isDigital(elem)" v-bind:key="elem.id">
              <b-col align="right" class="col-1  mt-3">
                <!-- <div> {{ resourceDisplayNameIO(namesIO.NAME) }}</div>
                <div> {{ resourceDisplayNameIO(namesIO.DESCRIPTION) }}</div> -->
                <div> {{ elem[namesIO.NAME] }}</div>
              </b-col>
              <b-col align="left" class="col-6 mt-3">
                <!-- <div> {{ elem[namesIO.NAME] }}</div> -->
                <div> {{ elem[namesIO.DESCRIPTION] }}</div>

              </b-col>
              <b-col align="center" class="col-5" >
                <p class="h3 mb-2 mt-0">
                  <b-badge v-if="getElementMetaData(elem).type==='badge'" pill :variant="getElementMetaData(elem).class">{{ getElementMetaData(elem).content }} </b-badge>
                  <b-icon v-if="getElementMetaData(elem).type==='icon'" :icon="getElementMetaData(elem).content" scale="1" :variant="getElementMetaData(elem).class"></b-icon>
                </p>
              </b-col>
            </b-row>
          </template>
        </b-card>

        <b-card
          align="center"
          header="Entrada Analogica"
          border-variant="info"
          header-bg-variant="info"
          header-text-variant="light"
          >
          <template v-for="elem in item[names.INPUTS]">
            <b-row v-if="isEnable(elem) & !isDigital(elem)" v-bind:key="elem.id">
              <b-col align="right">
                <div> {{ resourceDisplayNameIO(namesIO.NAME) }}</div>
                <div> {{ resourceDisplayNameIO(namesIO.DESCRIPTION) }}</div>
              </b-col>
              <b-col align="left">
                <div> {{ elem[namesIO.NAME] }}</div>
                <div> {{ elem[namesIO.DESCRIPTION] }}</div>
              </b-col>
              <b-col align="center">
                Valor
                <p class="h2 mb-2">
                  {{ elem[namesIO.VALUE] }}
                </p>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-card-group>

    </template>

  </crud>
</div>
</template>

<script>
import Crud from '@/components/Crud/Index';
import resources from '@/mixins/resourcesIOApp'
import customCell from '@/mixins/customCell'
import dataTable from "./data";

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import vehiculosService from "@/../../ingeo-mf-styleguide/src/services/vehiculos"
import comandoService from "@/../../ingeo-mf-styleguide/src/services/comando"
/// #else
import { vehiculosService } from "@ingeo-mf/styleguide";
import { comandoService } from "@ingeo-mf/styleguide";
/// #endif

export default {
	components: {
    Crud
  },
  mixins: [resources, customCell],
  data () {
    return {
      tanque: {
        value: 0,
        fecha: "-"
      },
      titleModule: "Ajuste",
      titleSubModule: "Telegestion",
      isViewForm: false,
      indexSelected: -1,
      currentValue: 20,
      updateOutputFromMirrorInput: true,

      fields: resources.fields,
      names: resources.names,
      namesIO: resources.namesIO,
      items: dataTable.items,
      command: {
          estado_id: "PND",
          veh_id: "",
          cmd_texto: "",
          cmd_tracker_id: "",
          cmd_intentos_pendientes: "3",
      },
    }
  },
  computed: {
    titleView() {
      return `${this.titleModule} / ${this.titleSubModule}`
    },
    fileName() {
      return `${this.titleModule}_${this.titleSubModule}`
    }
  },
  mounted() {
    this.updateInputValues(true);
    this.startPeriodicInputUpdate();
  },
  methods: {

    getElementFromKeyName(keyName, group, item) {
      let keyElement = null;
      item[group].forEach(element => {
          if (element.key_name === keyName) {
            keyElement = element;
          }
      });
      return keyElement;
    },
    getElementMetaData (elementId) {
      const elementMetaData  = elementId
                               .value_metadata
                               .find( item =>
                                  {
                                    if (typeof elementId.updating === 'undefined')
                                    {
                                      return (item.value === elementId.value)
                                    }
                                    else {
                                      return  (item.value === elementId.value)
                                                && (item.updating === elementId.updating)
                                    }
                                  }
                               );
      if (!elementMetaData){
        console.log("Error con elementMetaData: ", elementId);
      }
      return elementMetaData;
    },
    getCommandOnParams (itemId) {
      let cmdOn = this.command;
      cmdOn.veh_id = itemId.veh_id;
      cmdOn.cmd_tracker_id = itemId.tracker_id;
      cmdOn.cmd_texto = this.commandTextValues.cmd_on;
      return cmdOn;
    },
    getCommandOffParams (itemId) {
      let cmdOff = this.command;
      cmdOff.veh_id = itemId.veh_id;
      cmdOff.cmd_tracker_id = itemId.tracker_id;
      cmdOff.cmd_texto = this.commandTextValues.cmd_off;
      return cmdOff;
    },
    getCommandParams (itemId, cmd_text) {
      let cmd = this.command;
      cmd.veh_id = itemId.veh_id;
      cmd.cmd_tracker_id = itemId.tracker_id;
      cmd.cmd_texto = cmd_text;
      return cmd;
    },
    startPeriodicInputUpdate() {
        var vm=this
        this.mapUpdateTimer = setInterval(function () {
            vm.updateInputValues(vm.updateOutputFromMirrorInput)
        }, 40000)
    },
    updateInputValues(updateOutput) {

        // vehiculosService.feeds('TANQUE').then(tq => {
        vehiculosService.analogInput('TANQUE',1 , 10).then(tq => {

          const sensorSinPresionV= 7.15;
          const resistencia = 1858;
          const mAsinPresion = sensorSinPresionV / resistencia;

          if (Array.isArray(tq.data[0].log_feed) && tq.data[0].log_feed.length) {
            let lastVal=0;
            let valueCntr=0;
            tq.data[0].log_feed.forEach(element => {
              if (element.log_feed_valor > 7) {
                lastVal += element.log_feed_valor;
                valueCntr++;
              }
            })
            if (valueCntr) {
              lastVal /= valueCntr;
              console.log("Feeds Integrados: ", lastVal, valueCntr );
            }
            else {
              lastval = tq.data[0].log_chan_last_val;
            }

            const mAdiff = ( lastVal / resistencia ) - mAsinPresion;
            const presion = mAdiff / 0.00122;  // 1.22 ma/Bar empirico (deberia ser 1.6)

            this.tanque.value = presion.toFixed(2);
            this.tanque.fecha = tq.data[0].log_chan_fecha_update;
          }
          else {
            console.log("Feed Tanque con ruido: ", tq.data);
          }
            // if (Array.isArray(tq.data) && tq.data.length) {
            //   const feedItem = tq.data.find(x => x.log_var_id === "AAG001");
            //     if (feedItem == null){
            //       console.log("Feed indefinido Tanque: ", tq.data);
            //     }
            //     else{
            //       if (feedItem.log_chan_last_val > 7){
            //         const mAdiff = ( feedItem.log_chan_last_val / resistencia ) - mAsinPresion;
            //         const presion = mAdiff / 0.00122;  // 1.22 ma/Bar empirico (deberia ser 1.6)
            //          if (this.tanque.value){
            //           this.tanque.value = presion.toFixed(2);
            //         }
            //         else {
            //           this.tanque.value = (( this.tanque.value + presion) / 2).toFixed(2);
            //         }
            //         this.tanque.fecha = feedItem.log_chan_fecha_update;
            //       }
            //       else {
            //       console.log("Feed Tanque con ruido: ", tq.data);
            //       }
            //     }
            // }
        });

      this.items.forEach( item => {

        vehiculosService.feeds(item.veh_id).then(res => {

          item.inputs.forEach(element => {
            if ( (element.enable) && (element.type ==="DIGITAL")) {
                if (Array.isArray(res.data) && res.data.length) {

                  const feedItem = res.data.find(x => x.log_var_id === "INP00" + element.id);
                    if (feedItem == null){
                      console.log("Feed indefinido veh, item, data: ", element.id, item.veh_id, res.data);
                    }
                    else{
                      element.value = feedItem.log_chan_last_val;
                      item.battery = feedItem.log_chan_fecha_update;
                    }

                  //TODO: revisar implementación.
                  if (updateOutput){
                    item.outputs[0].value = item.inputs[0].value;
                  }
                }
            }
          });
        });

        // item.inputs.forEach(element => {
        //   if ( (element.enable) && (element.type ==="DIGITAL")) {
        //     vehiculosService.digitalInput(item.veh_id, element.id).then(res => {
        //       if (Array.isArray(res.data) && res.data.length) {
        //           element.value = res.data[0].log_chan_last_val;

        //         //TODO: revisar implementación.
        //         if (updateOutput){
        //           item.outputs[0].value = item.inputs[0].value;
        //         }
        //       }
        //     });
        //   }
        // });
      });
    },
    isEnable(elem) {
      return elem[this.namesIO.ENABLE]?true:false
    },
    isDigital(elem) {
      return elem[this.namesIO.TYPE] === "DIGITAL"?true:false
    },
    isAAGEnable(item) {
      const inputs = item[this.names.INPUTS]
      return this.isEnable(inputs[4]) && !this.isDigital(inputs[4])
    },
    getAAGValue(item) {
      const inputs = item[this.names.INPUTS]
      return inputs[4][this.namesIO.VALUE]
    },
    getDigitaInState(elem) {
      if(elem[this.namesIO.ENABLE] !== "true") {
        return {
          icon: "circle-fill",
          variant: "secondary",
          state: "Bloqueada"
        }
      }
      const isOn = parseInt(elem[this.namesIO.VALUE]) === 1 ? false:true
      return {
        icon: isOn ? "caret-up":"caret-down",
        variant: isOn ? "success":"danger",
        state: isOn ? "Activa":"Deshabilitada"
      }
    },
    getOutEnable(item) {
      const isEnable = item[this.names.ENABLE] ? true:false
      return {
        state: isEnable ? "Habilidato":"Deshabilitado",
        icon: isEnable ? "check-circle-fill":"x-circle-fill",
        variant: isEnable ? "success":"danger"
      }
    },
    getOutState(item) {
      // IN0 = Confirmación de marcha. 0-Marcha 1-Parado
      const inputs = item[this.names.INPUTS]
      const isOn = inputs[0][this.namesIO.VALUE] === 0 ? true:false
      return {
        state: isOn ? "Activa":"Deshabilitada",
        text: isOn ? "ON":"OFF",
        variant: isOn ? "success":"danger",
        icon: isOn ? "arrow-clockwise":"x",
        animation: isOn ? "spin":"fade"
      }
    },
    getOutMode(item) {
      // IN0 = Confirmación de marcha. 0-Marcha 1-Parado
      // IN1 = Estado Automatico. 0-Auto 1-Stop/Manual
      // IN2 = GuardaMotor. 0-Falla 1-OK
      // const inputs = item[this.names.INPUTS]

      const element = this.getElementFromKeyName('modo', 'inputs', item);
      const metaData = this.getElementMetaData(element);
      if (metaData) {
        return {text: metaData.content, variant: metaData.class, variantText: "white"}
      }
      return {text: "-", variant: "dark", variantText: "white"}

      // if (parseInt(inputs[1][this.namesIO.VALUE]) === 0) {

      //   if (parseInt(inputs[1][this.namesIO.VALUE]) === 0) {
      //     return {text: "AUTOMATICO", variant: "dark", variantText: "white"}
      //   }
      //   else if (parseInt(inputs[0][this.namesIO.VALUE]) === 0) {
      //     return {text: "MANUAL", variant: "secondary", variantText: "white"}
      //   }
      //   return {text: "CERO", variant: "secondary", variantText: "white"}
      // }
    },
    isAlarmGuardaMotor(item) {
      // IN0 = Confirmación de marcha. 0-Marcha 1-Parado
      // IN1 = Estado Automatico. 0-Auto 1-Stop/Manual
      // IN2 = GuardaMotor. 0-Falla 1-OK
      // XX = Energia.  a definir....
      const inputs = item[this.names.INPUTS]
      return inputs[2][this.namesIO.VALUE] === "0"?true:false
    },
    isAlarmConfirmacionMarcha(item) {
      const inputs = item[this.names.INPUTS]
      const outputs = item[this.names.OUTPUTS]
      // Salida Activa, Confirmacion Parado y Modo Auto = ALARMA CONFIRMACION MARCHA
      const alarm = outputs[0][this.namesIO.VALUE] === "1"
                    && inputs[0][this.namesIO.VALUE] === "1"
                    && inputs[1][this.namesIO.VALUE] === "0"
      return alarm?true:false
    },
    isAlarmEnergia(item) {
      const battery = item[this.names.BATTERY]
      return battery === "0"?true:false
    },
    getBtnOutVariant(elem) {
      const isOn =  parseInt(elem[this.namesIO.VALUE])  === 1 ? true:false
      return isOn?"outline-success":"outline-danger"
    },

    setOutput(item, elem) {
      this.updateOutputFromMirrorInput = false
      let cmdList = [];
      this.getElementMetaData(elem).cmd_text.forEach(cmd => {
        cmdList.push(cmd);
      });
      console.log ( "command list : ", cmdList);
      this.sendNextCommand(cmdList, item, elem);
      // elem.value = -1;
      elem.updating = true;
    },

    sendNextCommand(cmdList, item, elem) {
      let cmd = cmdList.pop();
      if ( cmd ) {
        comandoService.create(item.veh_id, this.getCommandParams(item, cmd))
                      .then(res => {
              console.log( "Comando enviado: ",res);
              if (!this.sendNextCommand(cmdList, item, elem)) {
                this.waitForCommandAck(res.data.comando.cmd_id, item, elem);
              }
        });
        return cmd;
      }
      return null;
    },

    waitForCommandAck(cmdId, item, elem) {

      comandoService.get(cmdId).then(res => {
          if (res.data[0].estado_id === 'PND') {
            var self = this
            setTimeout(function () {
              return self.waitForCommandAck(cmdId, item, elem);
            }, 20000);
          }
          else {
            if (res.data[0].estado_id === 'ACK') {
              //FIXME: El comando RPI no siempre devuelve el valor
              // actualizado de las entradas mirror, para actualizar
              // correctamente el cambio de estado.
              // Analizar si se puede obtener con algún otro comando.
              // Se comenta la actualización por ahora, y se habilita
              // updateOutputFromMirrorInput con la intención de que la
              // proxima encuesta periódica tenga el dato actualizado.
              // this.updateOutputFromMirror(item, elem);
              this.waitForMirrorInput(item, elem);
              // this.updateOutputFromMirrorInput = true
            }
            return res.data[0].estado_id;
          }
      });
    },

    waitForMirrorInput(item, elem) {
      //TODO: verificar que exista elem.mirror_input_id y mirror_expected_value
      vehiculosService.digitalInput(item.veh_id,
                                    item.inputs[elem.mirror_input_id].id)
                                    .then(res => {
          const expectedMirrorValue = parseInt(this.getElementMetaData(elem).mirror_expected_value)
          console.log ("Esperando confirmación: ", elem, expectedMirrorValue);
          if (parseInt(res.data[0].log_chan_last_val) !== expectedMirrorValue) {
            var self = this
            setTimeout(function () {
              return self.waitForMirrorInput(item, elem);
            }, 20000);
          }
          else {
            elem.value = res.data[0].log_chan_last_val;
            console.log('>>>>> OUTPUT STATUS CHANGE: ', elem);
            this.updateOutputFromMirrorInput = true
            elem.updating = false;
        }
      });
    },

    //TODO: utilizar función de validación de respuesta.
    isValidResponse(response) {
      if (Array.isArray(response.data) && response.data.length){
        return false
      }
      return true
    },

    updateOutputFromMirror(item, elem) {

      //TODO: verificar que exista elem.mirror_input_id
      vehiculosService.digitalInput(item.veh_id,
                                    item.inputs[elem.mirror_input_id].id)
                                    .then(res => {
        if (Array.isArray(res.data) && res.data.length) {
            elem.value = res.data[0].log_chan_last_val;
            item.inputs[elem.mirror_input_id].value = log_chan_last_val;
            console.log('>>>>> OUTPUT STATUS CHANGE: ', elem);
        }
      });
    },

  }
}


</script>

<style scoped>
.card-command-detail {
  min-height: 130px;
}
.btn-circle {
    width: 90px;
    height: 90px;
    border-radius: 45px;
    text-align: center;
    font-size: 12px;
    font-weight: bolder;
    line-height: 1.42857;
    z-index: 9;
    top: 12px;
}
.btn-output-cmd{
    position: absolute;
    opacity: 0.8;
}
.progress-circle {
    top: 0px;
    left: 0px;
}
</style>
