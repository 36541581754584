import Home from '@/views/Home/Index'

import IOApp from '@/views/IOApp/Index'

const routes = [
  {
    path: '/command',
    name: 'HomeCommand',
    component: Home,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/command/ioapp',
    name: 'Telegestion',
    component: IOApp,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {path: '*', component: Home}   // cambiar a not found?
];

export default routes
