const items = [
  {
    tracker_id: "486",
    veh_id: "POZO_01",
    tracker_name: "ID#486",
    cli_id: "8",
    tracker_description: "POZO 1",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'QID']
          },
          {
            value: 0,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'QID']
          },
          {
            value: -1,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: ''
          }
        ]
      },
    ]
  },
  {
    tracker_id: "484",
    veh_id: "POZO_02",
    tracker_name: "ID#484",
    cli_id: "8",
    tracker_description: "POZO 2",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
  {
    tracker_id: "785",
    veh_id: "POZO_03",
    tracker_name: "ID#785",
    cli_id: "8",
    tracker_description: "POZO 3",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
  {
    tracker_id: "775",
    veh_id: "POZO_04",
    tracker_name: "ID#775",
    cli_id: "8",
    tracker_description: "POZO 4",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
  {
    tracker_id: "784",
    veh_id: "POZO_05",
    tracker_name: "ID#784",
    cli_id: "8",
    tracker_description: "POZO 5",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
  {
    tracker_id: "210",
    veh_id: "POZO_06",
    tracker_name: "ID#210",
    cli_id: "8",
    tracker_description: "POZO 6",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: 1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
  {
    tracker_id: "776",
    veh_id: "POZO_07",
    tracker_name: "ID#776",
    cli_id: "8",
    tracker_description: "POZO 7",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
  {
    tracker_id: "239",
    veh_id: "POZO_08",
    tracker_name: "ID#239",
    cli_id: "8",
    tracker_description: "POZO 8",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
  {
    tracker_id: "783",
    veh_id: "POZO_09",
    tracker_name: "ID#783",
    cli_id: "8",
    tracker_description: "POZO 9",
    enable: true,
    battery: "-",
    inputs: [
      {
        id: 0,
        name: "",
        key_name: "marcha",
        description: "Confirmacion de Marcha",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'EN MARCHA',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'PARADO',
            class:'danger'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 1,
        name: "",
        key_name: "modo",
        description: "Modo",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'badge',   // icon, text, badge
            content: 'AUTOMATICO',
            class:'success'
          },
          {
            value: 1,
            type: 'badge',
            content: 'NO AUTOMATICO',
            class:'warning'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      {
        id: 2,
        name: "",
        key_name: "guardamotor",
        description: "Guardamotor",
        value: -1,
        type: "DIGITAL",
        enable: true,
        value_metadata: [
          {
            value: 0,
            type: 'icon',   // icon, text, badge
            content: 'x-circle-fill',
            class:'danger'
          },
          {
            value: 1,
            type: 'icon',
            content: 'check-circle-fill',
            class:'success'
          },
          {
            value: -1,
            type: 'badge',
            content: 'SIN DATOS',
            class:'danger'
          }
        ]
      },
      // {
      //   id: "3",
      //   name: "entrada AAG 1",
      //   description: "sensor Pozo 1",
      //   value: "0.5",
      //   type: "ANALOG",
      //   enable: "false"
      // },
    ],
    outputs: [
      {
        id: 0,
        name: "Salida 1",
        key_name: "activacion",
        description: "Activación",
        value: -1,
        type: "DIGITAL",
        enable: true,
        updating: false,
        mirror_input_id: 0,
        value_metadata: [
          {
            value: 1,
            updating: false,
            type: 'text',
            content: 'Activar',
            class:'danger',
            cmd_text: [ 'QPI', 'SAU0100000'],
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: false,
            type: 'text',
            content: 'Desactivar',
            class:'success',
            cmd_text: [ 'QPI', 'SAU0000000'],
            mirror_expected_value: 1
          },
          {
            value: 1,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 0
          },
          {
            value: 0,
            updating: true,
            type: 'text',
            content: 'Espere',
            class:'disabled',
            cmd_text: '',
            mirror_expected_value: 1
          }
        ]
      },
    ]
  },
];

export default { items };
